import image1 from '../images/lectures/image-01.jpg';
import image2 from '../images/lectures/image-02.jpg';
import image3 from '../images/lectures/image-03.jpg';
import image4 from '../images/lectures/image-04.jpg';
import image5 from '../images/lectures/image-05.jpg';
import image6 from '../images/lectures/image-06.jpg';

const lecturesImages = [
    image1,
    image6,
    image3,
    image5,
    image2,
    image4
]

export default lecturesImages;