import closeIcon from '../../images/close-icon-red.svg';
import menuIcon from '../../images/menu-icon-red.svg';
import { useState, useContext } from 'react';
import { languageContext } from "../../context/languageContext";

function Navigation(props) {
    // console.log(props.currentPage);

    const language = useContext(languageContext);
    const isMobile = props.isMobile;

    const [isMenuNavOpen, setMenuNavOpen] = useState(false);

    function openMenuNav() {
        isMenuNavOpen ? 
            setMenuNavOpen(false) 
            :
            setMenuNavOpen(true);
    }

    function focusToItem(id) {
        props.navLinks(id);
        setMenuNavOpen(false);
    }

    return(
        <nav className="navigation">
            {
                isMobile ? 
                <div className='navigation__mobile'>
                    <button className='navigation__icon' onClick={openMenuNav}>
                        <img className='navigation__icon-symbol' src={isMenuNavOpen ? closeIcon : menuIcon } alt={isMenuNavOpen ? 'Close icon' : 'Menu icon' } />
                    </button>
                    <h2 className='navigation__title'>
                        {
                            language === 'he' ? ' שי-אל בורנשטיין' : 'Shay-El Bornshtein'
                        }
                    </h2>
                </div> 
                :
                ''
            }

            {
                !isMobile ? 
                <h2 className='navigation__title'>
                    {
                        language === 'he' ? ' שי-אל בורנשטיין' : 'Shay-El Bornshtein'
                    }
                </h2>
                :
                ''
            }
            
            
            <ul className='navigation__links' style={isMenuNavOpen && isMobile ? {display: 'flex'} : !isMobile ? {display: 'flex'} : {display: 'none'} }>
                <li className='navigation__link-item'>
                    <button 
                        className={`navigation__button${props.currentPage === 'header' ? '_active' : ''}`} 
                        onClick={() => focusToItem('header')}
                        tabIndex={1}
                    >
                            {language === 'he' ? 'ראשי' : 'Main'}
                    </button>
                </li>
                <li className='navigation__link-item'>
                    <button 
                        className={`navigation__button${props.currentPage === 'about' ? '_active' : ''}`} 
                        onClick={() => focusToItem('about')}
                        tabIndex={2}
                    >
                        {language === 'he' ? 'אודות' : 'About'}
                    </button>
                </li>
                <li className='navigation__link-item'>
                    <button 
                        className={`navigation__button${props.currentPage === 'courses' ? '_active' : ''}`} 
                        onClick={() => focusToItem('courses')}
                        tabIndex={3}
                    >
                        {language === 'he' ? 'קורסים' : 'Courses'}
                    </button>
                </li>
                <li className='navigation__link-item'>
                    <button 
                        className={`navigation__button${props.currentPage === 'lectures' ? '_active' : ''}`} 
                        onClick={() => focusToItem('lectures')}
                        tabIndex={4}
                    >
                        {language === 'he' ? 'הרצאות' : 'Lectures'}
                    </button>
                </li>
                <li className='navigation__link-item'>
                    <button 
                        className={`navigation__button${props.currentPage === 'price-list' ? '_active' : ''}`} 
                        onClick={() => focusToItem('price-list')}
                        tabIndex={5}
                    >
                        {language === 'he' ? 'תעריפון' : 'Prices'}
                    </button>   
                </li>
            </ul>
        </nav>
    )
}

export default Navigation;
