import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import personalDataUtils from '../../utils/personalData.json';

function Header(props) {

    const language = useContext(languageContext);

    const [personalData, setPersonalData] = useState({});
  
    useEffect(() => {
        language === 'he' ? 
            setPersonalData(personalDataUtils.he)
            :
            setPersonalData(personalDataUtils.en);
    }, [language]);

    return(
        <header className="header" id="header">
            <div className="header__background">
                <div className="header__overlay">            
                    <h1 className='header__title'>{personalData.firstName} {personalData.lastName}</h1>
                    <p className="header__subtitle">{personalData.about1}</p>
                    <p className="header__subtitle">{personalData.about2}</p>
                    {/* <button 
                        className="header__language-button"
                        onClick={props.changeLanguage}
                        style={language === 'he' ? {"left": "15px"} : {"right": "15px"}}
                    >
                            {language === 'he' ? 'English' : 'עברית'}
                    </button> */}
                </div>
            </div>
        </header>
    )
}

export default Header;