import image1 from '../images/courses/image-01.jpg';
import image2 from '../images/courses/image-02.jpg';
import image3 from '../images/courses/image-03.jpg';
import image4 from '../images/courses/image-04.jpg';
import image5 from '../images/courses/image-05.jpg';
import image6 from '../images/courses/image-06.jpg';
import image7 from '../images/courses/image-07.jpg';
import image8 from '../images/courses/image-08.jpg';
import image9 from '../images/courses/image-09.jpg';
import image10 from '../images/courses/image-10.jpg';
// import image11 from '../images/courses/image-11.jpg';
import image12 from '../images/courses/image-12.jpg';
import image13 from '../images/courses/image-13.jpg';
import image14 from '../images/courses/image-14.jpg';
import image15 from '../images/courses/image-15.jpg';
import image16 from '../images/courses/image-16.jpg';
// import image17 from '../images/courses/image-17.jpg';
import imageBig1 from '../images/courses/image-big-01.jpg';
import imageBig2 from '../images/courses/image-big-02.jpg';



const coursesImages = {
    images1: [
        image7,
        image14,
        image10,
        image16,
        image8, 
        image15,
        image9,
        image13,
        image12
    ],

    images2: [
        image1, 
        image4, 
        image2,
        image3, 
        image5,
        image6
    ],
    bigImages: [
        imageBig1,
        imageBig2
        
    ]
}

export default coursesImages;
