import './accessibility-statement.css'
import './_active/accessibility-statement_active.css';
import './__textbox/accessibility-statement__textbox.css';
import './__container/accessibility-statement__container.css';
import './__close/accessibility-statement__close.css';
import './__title/accessibility-statement__title.css';
import './__text/accessibility-statement__text.css';
import closeIcon from '../../images/close-icon-red.svg';
import { useEffect } from 'react';

function AccessibilityStatement(props) {

    function closeAccessibilityStatement() {
        props.handleAccessibilityStatement()
    }

    useEffect(() => {
        if (!props.openAccessibilityStatement) return;

        const closeByEscape = (e) => {
            if (e.keyCode === 27) {
                closeAccessibilityStatement();
            }
        }

        document.addEventListener('keydown', closeByEscape)
        return () => document.removeEventListener('keydown', closeByEscape)
    }, [props.openAccessibilityStatement, closeAccessibilityStatement]);

    return (
        <section className={`accessibility-statement${props.openAccessibilityStatement ? '_active' : ''}`}>
            <div className='accessibility-statement__container'>
                <button 
                    className='accessibility-statement__close' 
                    onClick={closeAccessibilityStatement}
                    tabIndex={10}
                >
                    <img className='accessibility-statement__close-icon' src={closeIcon} alt='Close icon' />
                </button>
                <h2 className='accessibility-statement__title'>הצהרת נגישות</h2>
                <div className='accessibility-statement__textbox'>
                    <p className='accessibility-statement__text'>
                        על פי תיקון לתקנות העוסקות בנגישות האינטרנט אתר זה פטור מחובת נגישות. האתר הינו מופעל על ידי אדם פרטי (לא עמותה או חברה מסחרית בע"מ) אשר פועל כעוסק פטור זעיר.
                    </p>
                    <p className='accessibility-statement__text'>
                        האתר נבנה ומתוחזק בידי אדם פרטי שבנה את האתר הזה ללא מטרת רווח אישי. בבניית האתר הושקעו זמן ומאמצים רבים בהנגשת המידע שבו מתחילת בנייתו. האתר מונגש בהתאם לתקנה 35, ת.י 5568 להנגשת אתרי אינטרנט ו-Wcag, בתקן AA. בדף זה אפרט על הכלים והשיטות שהשתמשתי להנגשת האתר, וכן כיצד ניתן לפנות אלינו אם יש צורך בתיקון תקלה או שיפור הנגישות.
                    </p>
                    <p className='accessibility-statement__text'>
                        האתר הונגש באופן הבא:
                        <ol>
                            <li>התאמת האתר  לכל סוגי הדפדפנים המודרניים: Chrome, Firefox, Microsoft Edge (למעט אינטרנט אקספלורר).</li>
                            <li>התאמת האתר לכל סוגי הפלטפורמות - טלפון חכם, מחשב לוח (Tablet), ומחשב שולחני.</li>
                            <li>התאמת האתר לשימוש במקלדת בלבד באמצעות ניווט מקלדת ע"י מקש TAB וצירוף מקשים Shift+TAB.</li>
                        </ol>
                    </p>
                    <p className='accessibility-statement__text'>
                        על מנת להנגיש את האתר ותכניו, נעשו הפעולות הבאות:
                        <ol>
                            <li>פעולות ובדיקות באתר על מנת שיתאים לכל הדפדפנים ולכל הפלטפורמות (רספונסיביות).</li>
                            <li>בדיקת ניגודיות בצבעים, הוספת טקסט הסבר לכל תמונה והוספת טקסט אלטרנטיבי לכל תמונה.</li>
                            <li>קטעי הוידאו שמופיעים באתר הינם לצורך המחשה בלבד, והם אינם מכילים מידע אינפורמטיבי על השירותים הניתנים על ידי בעל העסק.</li>
                        </ol>
                    </p>
                    <p className='accessibility-statement__text'>
                        הכלים בהם נעשה שימוש לבדיקת הנגישות הם:
                        <ol>
                            <li>תקן הנגישות עצמו כפי שמפורט באתר ה-W3C.</li>
                            <li>קריטריוני הבדיקה כפי שמופיעים באתר הנגישות הישראלי.</li>
                            <li>סריקה ידנית של קוד המקור של האתר.</li>
                            <li>שימוש ב-WAVE לבדיקת ניגודית באתר, לבדיקת האתר ולסריקה כללית לפי תקן WCAG2.</li>
                            <li>שימוש בתגים המיוחדים שניתנים לשימוש ב-HTML5.</li>
                        </ol>
                    </p>
                    <p className='accessibility-statement__text'>
                        כפי שניתן לראות, בהנגשת האתר הושקעו זמן ומאמצים מרובים. עם זאת, ברור לנו שהאתר עדיין לא מונגש באופן מלא לכלל האוכלוסיות בעלי מוגבלויות שונות. במידה וישנה בעיית נגישות נקודתית ניתן לפנות בהודעה באמצעות הדוא"ל ואנחנו נעשה את מירב המאמצים לטפל ולפתור את בעיית הנגישות.
                        <ul>
                            <li>שי-אל בורנשטיין (בעל האתר) - shayel007@yahoo.com</li>
                            <li>ניצן כהן (מפתח האתר) - nitzancohen@hotmail.com</li>
                        </ul>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AccessibilityStatement;