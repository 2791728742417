import './app.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Courses from '../Courses/Courses';
import Lectures from '../Lectures/Lectures';
import PriceList from '../PriceList/PriceList';
import Navigation from '../Navigation/Navigation';
import AccessibilityStatement from '../AccessibilityStatement/AccessibilityStatement';
import { useEffect, useState } from 'react';
import { languageContext } from '../../context/languageContext';
// import Loader from '../Loader/Loader';

function App() {

  const [page, setPage] = useState('');
  // const [isLoading, setIsLoading] = useState(true);

  function navigationByLinks(id) {
    setPage(id);
  }

  useEffect(() => {
    if (page === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const element = document.getElementById(page)
        element.scrollIntoView();
      }, 0)
    }
    
  }, [page]);

  const [language, setLanguage] = useState('he');
  
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 990;

  function changeLanguage() {
    if (language === 'he') {
        setLanguage('en');
    } else if (language === 'en') {
        setLanguage('he');
    }
  }

  const [openAccessibilityStatement, setOpenAccessibilityStatemen] = useState(false);
  
  function handleAccessibilityStatement() {
    if (openAccessibilityStatement) {
      setOpenAccessibilityStatemen(false);  
    } else {
      setOpenAccessibilityStatemen(true);
    }
  }

  // window.onload = (event) => {
  //   // Code to be executed when all resources have finished loading
  //   console.log(event.target);
  //   setIsLoading(false);
  //   console.log("All resources have finished loading");
  // }
  
  // window.addEventListener("load", function () {
  //   setTimeout( function () {
  //     setIsLoading(false);
  //     console.log("page is fully loaded");
  //   }, 1000)
  // });

  // useEffect(() => {
  //   if (!openAccessibilityStatement) {
  //     setOpenAccessibilityStatemen(true);
  //   } else {
  //     setOpenAccessibilityStatemen(false);
  //   }
  // }, [openAccessibilityStatement])

  return (
    <languageContext.Provider value={language}> 
      <div className='app' style={language === 'he' ? {"direction": "rtl"} : {"direction": "ltr"}}>

        <Navigation 
          isMobile={isMobile}
          navLinks={navigationByLinks} 
          currentPage={page} 
        />

        <Header changeLanguage={changeLanguage} />

        <main>
          <About />
          <Courses 
            isMobile={isMobile}
          />
          <Lectures 
            isMobile={isMobile}
          />
          <PriceList />
          <Contact />
        </main>

        <Footer 
          handleAccessibilityStatement={handleAccessibilityStatement}
        />

        <AccessibilityStatement 
          openAccessibilityStatement={openAccessibilityStatement}
          handleAccessibilityStatement={handleAccessibilityStatement}
        />

      </div>
    </languageContext.Provider>
  );
}

export default App;
