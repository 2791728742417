import './__button/footer__button.css';
import { useEffect, useState, useContext } from 'react';
import { languageContext } from "../../context/languageContext";

function Footer(props) {

    const language = useContext(languageContext);
    const [year, setYear] = useState('');

    useEffect(() => {
        setYear(new Date().getFullYear());
    }, [year]);

    function openAccessibilityStatement() {
        props.handleAccessibilityStatement();
    }

    return(
        <footer className="footer" id="footer">
            <p className='footer__text'>
                {language === 'he' ?
                `האתר פותח ומתוחזק ע"י ניצן כהן © ${year}`
                :
                `Developed and powered by Nitzan Cohen © ${year}`
                }
            </p>
            <button 
                className='footer__button' 
                onClick={openAccessibilityStatement}
                tabIndex={9}
            >
                הצהרת נגישות
            </button>
        </footer>
    )
}

export default Footer;