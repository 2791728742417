import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import aboutDataUtils from "../../utils/aboutData.json";
import profileImage from "../../images/cover-photos/profile-about.jpg";

function About(props) {

    const language = useContext(languageContext);
    const [aboutDataData, setAboutDataData] = useState({});

    useEffect(() => {
        if (language === 'he') {
            setAboutDataData(aboutDataUtils.he);
        } else if (language === 'en') {
            setAboutDataData(aboutDataUtils.en);
        }
    }, [language]);

    return(
        <section className="about" id="about">
            <div className="about__content">
                <div className="every-section__title-block">
                    <h2 className="every-section__title">{aboutDataData.title}</h2>
                </div>
                <div className="about__main">
                    <img className="about__image" src={profileImage} alt="Shayel Bornshtein - profile" />
                    <div className="about__description">
                        <p>
                            שי-אל בורנשטיין הינו בוגר המסלול לחינוך גופני בסמינר הקיבוצים בעל תואר שני M.ed בחינוך לפעילות גופנית ובריאות.
                        </p>
                        <p>
                            משמש כיום כמרצה בקורס מדריכי כושר גופני ובריאות (חדר כושר), מרצה בקורס מאמנים אישיים,
                            מרצה בקורס אימון רצועות, בוחן ומסמיך מדריכי חדר כושר. 
                            מאושר על-ידי מנהל ספורט להוראת כלל מקצועות חדר הכושר המעשיים והעיוניים-מדעיים: 
                        </p>
                        <ul>
                            <li>תורת הכושר הגופני.</li>
                            <li>אימון אוכלוסיות מיוחדות.</li>
                            <li>אימון קטינים.</li>
                            <li>חדר כושר מעשי ובניית תוכניות אימונים.</li>
                            <li>תורת התנועה - קינזיולוגיה.</li>
                            <li>פיזיולוגיה.</li>
                            <li>אנטומיה.</li>
                            <li>תזונה.</li>
                        </ul>
                        <p>
                            בוגר תואר ראשון B.ed במסלול לחינוך גופני בסמינר הקיבוצים. 
                            כיום מורה לחינוך גופני ובעל תעודת הוראה ורשיון הוראה מטעם משרד החינוך. 
                        </p>
                        <p>
                            מזה שנים רבות מאמן כושר אישי ויועץ לאורח חיים פעיל ובריא. 
                            מלווה מתאמנים רבים בגילאים שונים ומגבלות שונות. 
                            בוגר קורס מדריכי כושר גופני ובריאות בהצטיינות יתרה מטעם קמפוס שיאים, 
                            אוניברסיטת ת"א. בתחילת דרכו אימן כמאמן אישי ברשת Go active. 
                            בוגר השתלמות מאמן אישי שלב 1, שלב 2 ושלב 3 - דרגת המאמן הבכירה ביותר ברשת.
                        </p>
                        <p>
                            לאורך השנים ביצע השתלמויות רבות. העיקריות הן:
                        </p>
                        <ul>
                            <li>קורס מאמנים אישיים (היום אף מלמד קורס זה).</li>
                            <li>מדריך TRX קבוצות בינלאומי.</li>
                            <li>מדריך שחייה.</li>
                            <li>מדריך אתלטיקה קלה.</li>
                            <li>הוראת 5 יחידות בגרות בחינוך גופני.</li>
                            <li>הוראת המקצועות המדעיים בקורסי מדריכים אשר בפיקוח מנהל הספורט.</li>
                        </ul>
                        <p>
                            שי-אל שירת בשירות קבע כקצין במגוון תפקידי הוראה והדרכה, 
                            ואף היום הוא בדרגת רב סרן במילואים במחלקת הכושר הקרבי של צה"ל.
                        </p>
                    </div>
                </div>
                
                
            </div>
            
        </section>
    )
}

export default About;