import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import ReactPlayer from "react-player/lazy";
import coursesDataUtils from "../../utils/coursesData.json";
import coursesImages from "../../utils/coursesImages";

function Courses(props) {

    const language = useContext(languageContext);
    const isMobile = props.isMobile;
    const [coursesDataData, setCoursesDataData] = useState({});
    const [images, setImages] = useState([]);

    const urlYouTubeStrapTraining = "https://www.youtube.com/watch?v=XYLudWPSkcI";
    const urlYoutubeGym = "https://www.youtube.com/watch?v=3ycc16qgk6s";
    
    useEffect(() => {
        if (isMobile) {
            setImages(coursesImages.images2.slice(0, 5));
        } else {
            setImages(coursesImages.images2);
        }
    }, [isMobile]);

    useEffect(() => {
        if (language === 'he') {
            setCoursesDataData(coursesDataUtils.he);
        } else if (language === 'en') {
            setCoursesDataData(coursesDataUtils.en);
        }
    }, [language]);

    

    return(
        <section className="courses" id="courses">
            <div className="courses__content">
                <div className="every-section__title-block">
                    <h2 className="every-section__title">{coursesDataData.title}</h2>
                </div>
                <p className="courses__text">הסמכת מדריכי כושר גופני ובריאות</p>
                <div className='courses__video'>
                    <ReactPlayer 
                        width='100%'
                        height='100%'
                        url={urlYoutubeGym}
                        muted={true}
                        playing={true}
                        loop={true}
                        controls={true}
                        allowFullScreen={false}
                    />
                </div>
                    
                {
                    !isMobile ?
                    <div className="courses__images-container">
                        <img className="courses__image_big" src={coursesImages.bigImages[0]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        {
                            coursesImages.images1.map((image, index) => (
                                <img className="courses__image" src={image} key={index} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                            ))
                        }
                    </div>
                    :
                    <div className="courses__images-container">
                        <img className="courses__image_big" src={coursesImages.bigImages[0]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[0]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[1]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[2]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[3]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image_big" src={coursesImages.images1[4]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[5]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[6]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[7]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                        <img className="courses__image" src={coursesImages.images1[8]} alt="שי-אל בורנשטיין - הסמכת מדריכי חדר כושר גופני ובריאות" />
                    </div>
                }
                
                
                <p className="courses__text">הסמכת קורס אימון אישי וקורס רצועות</p>
                <div className="courses__video">
                    <ReactPlayer 
                        width="100%"
                        height="100%"
                        url={urlYouTubeStrapTraining}
                        muted={true}
                        playing={true}
                        loop={true}
                        controls={true}
                        allowFullScreen={false}
                    />
                    </div>
                <div className="courses__images-container">
                    <img className="courses__image_big" src={coursesImages.bigImages[1]} alt="שי-אל בורנשטיין - קורס אימון אישי וקורס רצועות" />
                    {
                        images.map((image, index) => (
                            <img className="courses__image" src={image} key={index} alt="שי-אל בורנשטיין - קורס אימון אישי וקורס רצועות" />        
                        ))
                    }
                    {
                        isMobile ? 
                            <img className="courses__image_big" src={coursesImages.images2[5]} alt="שי-אל בורנשטיין - קורס אימון אישי וקורס רצועות" />
                            : 
                            ""
                    }
                    
                </div>
            </div>
        </section>
    )
}

export default Courses;
