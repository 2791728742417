import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import pricesListDataUtils from "../../utils/pricesListData.json";
import porductsPricesDataUtils from "../../utils/productsPricesData.json";
import cardsImages from "../../utils/cardsImages";
import Card from '../Card/Card';

function PriceList(props) {

    const language = useContext(languageContext);
    const [pricesListData, setPricesListData] = useState({});
    const [productsData, setProductsData] = useState([]);
    
    useEffect(() => {
        if (language === 'he') {
            setPricesListData(pricesListDataUtils.he);
            setProductsData(porductsPricesDataUtils.he);
        } else if (language === 'en') {
            setPricesListData(pricesListDataUtils.en);
            setProductsData(porductsPricesDataUtils.en);
        }
    }, [language]);

    return(
        <section className="price-list" id='price-list'>
            <div className="price-list__content">
                <div className="every-section__title-block">
                    <h2 className="every-section__title">{pricesListData.title}</h2>
                </div>
                <p className="price-list__text">{pricesListData.subtitle}</p>

                <div className="price-list__container">
                    {
                        productsData.map((product, index) => (
                            <Card 
                                key={index}
                                title={product.title}
                                price={product.price}
                                time={product.time}
                                amount={product.amount}
                                description={product.description}
                                image={cardsImages[product.id]}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default PriceList;