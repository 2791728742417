function Card(props) {

    // console.log(image02);

    return(
        <section className="card">
            <img className='card__image' src={props.image} alt="Card" />
            <div className="card__overlay">
                <h2 className="card__title">{props.title}</h2>
                <p className="card__time">{props.amount}</p>
                <p className="card__time">{props.time}</p>
                <p className="card__price">{props.price}</p>
            </div>
            <div className="card__overlay-active">
                <p className="card__description">{props.description}</p>
            </div>
        </section>
    )
}

export default Card;