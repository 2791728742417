import image1 from "../images/cards/dumblles01.jpg";
import imageNutrition from "../images/cards/nutrition02.jpg";
import imageLecture from "../images/lectures/image-05.jpg";
import imageStudy from "../images/cards/study03.jpg";

const cardsImages = [
    image1,
    image1,
    image1,
    image1,
    image1,
    imageNutrition,
    imageNutrition,
    imageStudy,
    imageStudy,
    imageStudy,
    imageStudy,
    imageLecture
]

export default cardsImages;
