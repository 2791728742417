import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import contactDataUtils from "../../utils/ContactData.json";
import whatsappIcon from "../../images/whatsapp-icon.png";
import telegramIcon from "../../images/telegram-icon.png";

function Contact(props) {

    const language = useContext(languageContext);
    const [contactDataData, setContactDataData] = useState({});
    const [isMenuContactOpen, setMenuContactOpen] = useState(false);

    useEffect(() => {
        if (language === 'he') {
            setContactDataData(contactDataUtils.he);
        } else if (language === 'en') {
            setContactDataData(contactDataUtils.en);
        }
    }, [language]);

    function changeModeMenuContact() {
        if (isMenuContactOpen) {
            setMenuContactOpen(false);
        } else {
            setMenuContactOpen(true);
        }
    }

    return(
        <section 
            className="contact"
            id="contact"
            style={ language === 'he' ? {'right': '15px'} : {'left': '15px'}}
        >
            {
                isMenuContactOpen ? 
                <ul className="contact__icons">
                    <li className="contact__item">
                        <a className="contact__link" tabIndex={7} href="https://wa.me/972524481088" target="_blank" rel="noreferrer">
                            <img className="contact__icon" src={whatsappIcon} alt="Whatsapp icon" /> 
                            <p className="contact__text">WhatsApp</p>
                        </a>
                    </li>
                    <li className="contact__item">
                        <a className="contact__link" tabIndex={8} href="https://t.me/shayelbornshtein" target="_blank" rel="noreferrer">
                            <img className="contact__icon" src={telegramIcon} alt="Telegram icon" /> 
                            <p className="contact__text">Telegram</p>
                        </a>
                    </li>
                </ul> : 
                ''
            }

            <button 
                className="contact__button" 
                onClick={changeModeMenuContact}
                tabIndex={6}
            >
                {contactDataData.title}
            </button>

        </section>
    )
}

export default Contact;
