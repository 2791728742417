import { useContext, useState, useEffect } from "react";
import { languageContext } from "../../context/languageContext";
import lecturesDataUtils from "../../utils/lectureData.json";
import lecturesImages from "../../utils/imagesLectures";

function Main(props) {

    const language = useContext(languageContext);
    const [lecturesDataData, setLecturesDataData] = useState({});

    useEffect(() => {
        if (language === 'he') {
            setLecturesDataData(lecturesDataUtils.he);
        } else if (language === 'en') {
            setLecturesDataData(lecturesDataUtils.en);
        }
    }, [language]);

    return(
        <main className="lectures" id="lectures">
            <div className="lectures__content">
                <div className="every-section__title-block">
                    <h2 className="every-section__title">{lecturesDataData.title}</h2>
                </div>
                <ul className="lectures__text">
                    <li>פעילות גופנית כרפואה מונעת בשלבי החיים השונים</li>
                    <li>ירידה בריאה במשקל</li>
                    <li>תזונה נכונה כרפואה מונעת</li>
                    <li>תזונה צמחית ופעילות גופנית</li>
                </ul>
                {
                    props.isMobile ?
                    <div className="lectures__images-container">
                        <img className="lectures__image_big" src={lecturesImages[0]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                        <img className="lectures__image" src={lecturesImages[1]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                        <img className="lectures__image" src={lecturesImages[2]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                        <img className="lectures__image_big" src={lecturesImages[3]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                        <img className="lectures__image" src={lecturesImages[4]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                        <img className="lectures__image" src={lecturesImages[5]} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />
                    </div>
                    :
                    <div className="lectures__images-container">
                        {lecturesImages.map((image, index) => (
                            <img className="lectures__image" src={image} key={index} alt="שי-אל בורנשטיין - הרצאות כושר גופני ותזונה" />        
                        ))}
                    </div>
                }
                
                {/* {
                    lecturesImages.map((image, index) => (
                        <img className="lectures__image" src={image} key={index} alt="Lectures" />        
                    ))
                } */}
            </div>
        </main>
    )
}

export default Main;
